import React from 'react';
import Link from '../utils/link';

// import layoutQuery from '../hooks/use-layout-query';
// import { And } from '../components/icons';

function Footer() {

  return (
    <>
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='col'>
            <h1>Ron Arad Studio</h1>
          </div>
          <div className='col'>
            <Link to="mailto:info@ronarad.com?subject=Hello">Contact</Link>
          </div>
          <div className='col'>
            <Link className="instagram" to="https://instagram.com/ronaradstudio">@ronaradstudio</Link>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
