import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Apply fade-in class after component mounts
    setFadeIn(true);

    // Set a timer for fade-out
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 4000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleFadeOut = () => {
    setFadeOut(true);
  };

  return (
    <header className='header'>
      <div className='header__inner'>
        <div className='header__center'>
          <div
            className={`logo ${fadeIn ? 'fade-in' : ''} ${fadeOut ? 'fade-out' : ''}`}
            onClick={handleFadeOut}
          >
            <StaticImage
              src="../assets/images/logo.png"
              alt="Ron Arad Studio"
              placeholder="none"
              backgroundColor="transparent"
              width={1000}
              height="auto"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
